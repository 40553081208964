import React from 'react';
import './hero2.component.css';

const Hero2 = () => {
    const heroGif = require('../../../assets/bannerGif.gif'); // Adjust path as needed
  return (
    <div className="hero2-container">
      <div className="hero2-content">
        <h1 className="hero2-title">
          [efd] <span className="relics-word">Relics</span>
        </h1>
        <p className="hero2-subtitle">
          A fusion of physical and technical craftsmanship.
        </p>
        <button className="hero2-button">Explore Relics</button>
      </div>
      <div className="hero2-image">
        <img src={heroGif} alt="Hero GIF" />
      </div>
    </div>
  );
};

export default Hero2;
