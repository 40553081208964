import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import BindRelic from '../bind-relic/bind-relic.page.js';
import './relic.page.css';

const Relic = ({ authToken }) => {
  const [relicData, setRelicData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const { relicID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRelic();
  }, []);

  const fetchRelic = async () => {
    try {
      const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${relicID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setRelicData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch relic:', error);
      setLoading(false);
    }
  };

  const handleDeleteRelic = async () => {
    try {
      await axios.delete(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${relicID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      navigate('/admin-dashboard/relics');
    } catch (error) {
      console.error('Error deleting relic:', error);
    }
  };

  const handleMintRelic = async () => {
    try {
      await axios.post(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${relicID}/mint`, {}, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      alert('Relic minted successfully!');
      fetchRelic(); // Refresh relic data after minting
    } catch (error) {
      console.error('Error minting relic:', error);
      alert('Failed to mint relic.');
    }
  };

  const handleUpdateRelic = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${relicID}`, relicData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      alert('Relic updated successfully!');
      setEditMode(false);
      fetchRelic(); // Refresh relic data after update
    } catch (error) {
      console.error('Error updating relic:', error);
      alert('Failed to update relic.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRelicData({
      ...relicData,
      [name]: value,
    });
  };

  if (loading) return <div>Loading...</div>;

  if (!relicData) return <div>Relic not found</div>;

  return (
    <div className="relic-details">
      {/* Column A1: Photo */}
      <div className="relic-image">
        <img src={relicData.imageUrl} alt={relicData.name} />
      </div>

      {/* Column B1: Name, Designer, Hallmark, Appraisal, Status */}
      <div className="basic-info">
        <h4 className="info-title">Name</h4>
        <div className="info-group">{relicData.name}</div>

        <h4 className="info-title">Designer</h4>
        <div className="info-group">{relicData.designer}</div>

        <h4 className="info-title">Hallmark</h4>
        <div className="info-group">{relicData.hallmark}</div>

        <h4 className="info-title">Appraisal Value</h4>
        <div className="info-group">${relicData.appraisalValue}</div>

        <h4 className="info-title">Status</h4>
        <div className="info-group">{relicData.status}</div>
      </div>

      {/* Column A2: Materials, Center Stone, Accent Stones */}
      <div className="materials-info">
        <h4 className="info-title">Materials</h4>
        <div className="materials-container">
          {relicData.material && relicData.material.map((material, index) => (
            <div key={index} className="material-card">
              <p>{material}</p>
            </div>
          ))}
        </div>

        <h4 className="info-title">Center Stone</h4>
        <div className="info-group">
          {relicData.centerStone ? (
            <>
              <p>Mineral: {relicData.centerStone.mineral}</p>
              <p>Subspecies: {relicData.centerStone.subspecies}</p>
              <p>Weight: {relicData.centerStone.weight} ct</p>
              <p>Clarity: {relicData.centerStone.clarity}</p>
            </>
          ) : (
            <p>No center stone</p>
          )}
        </div>

        <h4 className="info-title">Accent Stones</h4>
        <div className="accent-stones-container">
          {relicData.accentStones && relicData.accentStones.map((stone, index) => (
            <div key={index} className="accent-stone-card">
              <p>Mineral: {stone.mineral}</p>
              <p>Subspecies: {stone.subspecies}</p>
              <p>Size: {stone.size} mm</p>
              <p>Amount: {stone.amount}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Column B2: Dimensions, Weight */}
      <div className="dimensions-info">
        <h4 className="info-title">Dimensions</h4>
        <div className="info-group">{relicData.dimensions}</div>

        <h4 className="info-title">Weight</h4>
        <div className="info-group">{relicData.weight} g</div>
      </div>

      {/* Action Buttons */}
      <div className="relic-actions">
        <button onClick={() => setEditMode(true)}>Edit</button>
        <button onClick={handleDeleteRelic}>Delete</button>
        {relicData.status !== 'minted' && (
          <>
            <button onClick={handleMintRelic}>Mint Relic</button>
            <BindRelic relicID={relicID} />
          </>
        )}
      </div>
    </div>
  );
};

export default Relic;
