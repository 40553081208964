import React, { useState } from 'react';
import axios from 'axios';
import './add-relic.page.css';
import { FaTimesCircle, FaPlus } from 'react-icons/fa';

const AddRelic = ({ authToken }) => {
  const [relicData, setRelicData] = useState({
    name: '',
    imageUrl: '',
    materials: [{ type: '' }],
    dimensions: { length: '', width: '', height: '' },
    weight: '',
    centerStone: { mineral: '', subspecies: '', weight: '', clarity: '' }, // Center Stone structure
    accentStones: [{ mineral: '', subspecies: '', size: '', amount: '' }], // Updated Accent Stones structure
    hallmark: '',
    designer: '',
    appraisalValue: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRelicData({
      ...relicData,
      [name]: value,
    });
  };

  const handleDimensionChange = (e) => {
    const { name, value } = e.target;
    setRelicData({
      ...relicData,
      dimensions: {
        ...relicData.dimensions,
        [name]: value,
      },
    });
  };

  const handleMaterialChange = (index, e) => {
    const { name, value } = e.target;
    const updatedMaterials = [...relicData.materials];
    updatedMaterials[index][name] = value;
    setRelicData({
      ...relicData,
      materials: updatedMaterials,
    });
  };

  const handleAddMaterial = () => {
    setRelicData({
      ...relicData,
      materials: [...relicData.materials, { type: '' }],
    });
  };

  const handleRemoveMaterial = (index) => {
    const updatedMaterials = relicData.materials.filter((_, i) => i !== index);
    setRelicData({
      ...relicData,
      materials: updatedMaterials,
    });
  };

  const handleAccentStoneChange = (index, e) => {
    const { name, value } = e.target;
    const updatedAccentStones = [...relicData.accentStones];
    updatedAccentStones[index][name] = value;
    setRelicData({
      ...relicData,
      accentStones: updatedAccentStones,
    });
  };

  const handleAddAccentStone = () => {
    setRelicData({
      ...relicData,
      accentStones: [...relicData.accentStones, { mineral: '', subspecies: '', size: '', amount: '' }],
    });
  };

  const handleRemoveAccentStone = (index) => {
    const updatedAccentStones = relicData.accentStones.filter((_, i) => i !== index);
    setRelicData({
      ...relicData,
      accentStones: updatedAccentStones,
    });
  };

  const handleCenterStoneChange = (e) => {
    const { name, value } = e.target;
    setRelicData({
      ...relicData,
      centerStone: {
        ...relicData.centerStone,
        [name]: value,
      },
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const pinataMetadata = JSON.stringify({
      name: file.name,
    });
    formData.append("pinataMetadata", pinataMetadata);

    const pinataOptions = JSON.stringify({
      cidVersion: 1,
    });
    formData.append("pinataOptions", pinataOptions);

    try {
      const res = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`
        },
        body: formData,
      });

      const response = await res.json();

      const imageUrl = `https://moccasin-loose-raccoon-132.mypinata.cloud/ipfs/${response.IpfsHash}`;
      
      setRelicData({
        ...relicData,
        imageUrl,
      });

      alert('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image to Pinata:', error);
      alert('Failed to upload image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('AuthToken:', authToken);

    if (!authToken) {
      alert('Missing authorization.');
      return;
    }

    try {
      const newRelic = {
        name: relicData.name,
        imageUrl: relicData.imageUrl,
        materials: relicData.materials,
        dimensions: `${relicData.dimensions.length} x ${relicData.dimensions.width} x ${relicData.dimensions.height}`,
        weight: relicData.weight,
        centerStone: relicData.centerStone, // Updated centerStone structure
        accentStones: relicData.accentStones, // Updated accentStones structure
        hallmark: relicData.hallmark,
        designer: relicData.designer,
        appraisalValue: relicData.appraisalValue,
      };

      const response = await axios.post(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics`, newRelic, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        alert('Relic added successfully!');
      } else {
        alert('Failed to add relic.');
      }
    } catch (error) {
      console.error('Error adding relic:', error);
      alert('An error occurred while adding the relic.');
    }
  };

  return (
    <div className="add-relic">
      <h2>Add New Relic</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Relic Name</label>
          <input
            type="text"
            name="name"
            value={relicData.name}
            onChange={handleChange}
            placeholder="Enter relic name"
            required
          />
        </div>

        <div className="form-group">
          <label>Image</label>
          <input type="file" onChange={handleImageUpload} required />
        </div>

        <div className="form-group">
          <label>Materials</label>
          {relicData.materials.map((material, index) => (
            <div key={index} className="material-input">
              <input
                type="text"
                name="type"
                value={material.type}
                onChange={(e) => handleMaterialChange(index, e)}
                placeholder="Material Type"
                required
              />
              <button
                type="button"
                className="remove-button"
                onClick={() => handleRemoveMaterial(index)}
              >
                <FaTimesCircle />
              </button>
            </div>
          ))}
          <button
            type="button"
            className="add-text-button"
            onClick={handleAddMaterial}
          >
            Add Material <FaPlus style={{ marginLeft: '5px' }} />
          </button>
        </div>

        <div className="form-group dimensions-section">
          <label>Dimensions (mm)</label>
          <div className="dimensions-inputs">
            <input
              type="number"
              name="length"
              value={relicData.dimensions.length}
              onChange={handleDimensionChange}
              placeholder="Length"
              required
            />
            <span>×</span>
            <input
              type="number"
              name="width"
              value={relicData.dimensions.width}
              onChange={handleDimensionChange}
              placeholder="Width"
              required
            />
            <span>×</span>
            <input
              type="number"
              name="height"
              value={relicData.dimensions.height}
              onChange={handleDimensionChange}
              placeholder="Height"
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Weight (g)</label>
          <input
            type="number"
            name="weight"
            value={relicData.weight}
            onChange={handleChange}
            placeholder="Enter weight"
            required
          />
        </div>

        <div className="form-group">
          <label>Center Stone</label>
          <input
            type="text"
            name="mineral"
            value={relicData.centerStone.mineral}
            onChange={handleCenterStoneChange}
            placeholder="Mineral"
            required
          />
          <input
            type="text"
            name="subspecies"
            value={relicData.centerStone.subspecies}
            onChange={handleCenterStoneChange}
            placeholder="Subspecies"
          />
          <input
            type="text"
            name="weight"
            value={relicData.centerStone.weight}
            onChange={handleCenterStoneChange}
            placeholder="Weight"
          />
          <input
            type="text"
            name="clarity"
            value={relicData.centerStone.clarity}
            onChange={handleCenterStoneChange}
            placeholder="Clarity"
          />
        </div>

        <div className="form-group">
          <label>Accent Stones</label>
          {relicData.accentStones.map((stone, index) => (
            <div key={index} className="accent-stone-input">
              <input
                type="text"
                name="mineral"
                value={stone.mineral}
                onChange={(e) => handleAccentStoneChange(index, e)}
                placeholder="Mineral"
                required
              />
              <input
                type="text"
                name="subspecies"
                value={stone.subspecies}
                onChange={(e) => handleAccentStoneChange(index, e)}
                placeholder="Subspecies"
              />
              <input
                type="text"
                name="size"
                value={stone.size}
                onChange={(e) => handleAccentStoneChange(index, e)}
                placeholder="Size"
              />
              <input
                type="number"
                name="amount"
                value={stone.amount}
                onChange={(e) => handleAccentStoneChange(index, e)}
                placeholder="Amount"
                required
              />
              <button
                type="button"
                className="remove-button"
                onClick={() => handleRemoveAccentStone(index)}
              >
                <FaTimesCircle />
              </button>
            </div>
          ))}
          <button
            type="button"
            className="add-text-button"
            onClick={handleAddAccentStone}
          >
            Add Stone <FaPlus style={{ marginLeft: '5px' }} />
          </button>
        </div>

        <div className="form-group">
          <label>Hallmark</label>
          <input
            type="text"
            name="hallmark"
            value={relicData.hallmark}
            onChange={handleChange}
            placeholder="Enter hallmark"
            required
          />
        </div>

        <div className="form-group">
          <label>Designer</label>
          <input
            type="text"
            name="designer"
            value={relicData.designer}
            onChange={handleChange}
            placeholder="Enter designer's name"
            required
          />
        </div>

        <div className="form-group">
          <label>Appraisal Value ($)</label>
          <input
            type="number"
            name="appraisalValue"
            value={relicData.appraisalValue}
            onChange={handleChange}
            placeholder="Enter appraisal value"
            required
          />
        </div>

        <div className="button-container">
          <button
            type="submit"
            className="add-text-button"
          >
            Add Relic <FaPlus />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddRelic;
