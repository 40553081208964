import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './relic-management.page.css';

const RelicManagement = ({ authToken }) => {
  const [relics, setRelics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const relicsPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    fetchRelics();
  }, []);

  const fetchRelics = async () => {
    try {
      const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data;
      const notMintedRelics = data.filter(relic => relic.status === 'not-minted');
      setRelics(notMintedRelics);
    } catch (error) {
      console.error('Failed to fetch relics:', error);
    }
  };

  const handleDeleteRelic = async (relicID) => {
    try {
      await axios.delete(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${relicID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setRelics(relics.filter(relic => relic.relicID !== relicID));
    } catch (error) {
      console.error('Error deleting relic:', error);
    }
  };

  const handleViewRelic = (relicID) => {
    navigate(`/admin-dashboard/relic/${relicID}`);
  };

  const handleAddRelic = () => {
    navigate('/admin-dashboard/add-relic');
  };

  const indexOfLastRelic = currentPage * relicsPerPage;
  const indexOfFirstRelic = indexOfLastRelic - relicsPerPage;
  const currentRelics = relics.slice(indexOfFirstRelic, indexOfLastRelic);

  const totalPages = Math.ceil(relics.length / relicsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="relic-management">
      <div className="relic-header">
        <h2>Manage Relics</h2>
        <button className="add-relic-button" onClick={handleAddRelic}>Add Relic</button>
      </div>
      <div className="relic-list">
        <ul>
          {currentRelics.map((relic) => (
            <li key={relic.relicID} onClick={() => handleViewRelic(relic.relicID)}>
              <div className="relic-card">
                <img src={relic.imageUrl} alt={relic.name} />
                <h3>{relic.name}</h3>
              </div>
              <div className="relic-actions">
                <button onClick={(e) => { e.stopPropagation(); handleDeleteRelic(relic.relicID); }}>Delete</button>
              </div>
            </li>
          ))}
        </ul>

        <div className="pagination">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default RelicManagement;
