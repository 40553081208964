import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './profile.page.css';

const ProfilePage = () => {
  const { username } = useParams(); // Get the username from the URL
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    // Fetch the profile data based on the username
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${process.enc.REACT_APP_API_BASE_URL}/api/v1/collectors/profile/${username}`);
        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
        } else {
          console.error('Failed to fetch profile data.');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [username]);

  if (!profileData) {
    return <p>Loading profile...</p>;
  }

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img src={profileData.picture} alt={`${profileData.username}'s profile`} className="profile-pic" />
        <h1>{profileData.username}</h1>
        <p className="bio">{profileData.bio}</p>
      </div>
      <div className="profile-content">
        <h2>Websites</h2>
        <ul>
          {profileData.websites.map((website, index) => (
            <li key={index}>
              <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
            </li>
          ))}
        </ul>

        <h2>Social Media</h2>
        <ul>
          {profileData.socials.map((social, index) => (
            <li key={index}>
              <span>{social.platform}: </span>
              <a href={`httpss://${social.platform}.com/${social.handle}`} target="_blank" rel="noopener noreferrer">
                {social.handle}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProfilePage;
