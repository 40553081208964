// src/component/footer/footer.component.js
import React from 'react';
import './footer.layout.css';
import { FaTwitter, FaInstagram, FaFacebookF } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>[engel fine design]</h2>
        </div>
        <div className="footer-links">
          <a href="/about">About Us</a>
          <a href="/contact">Contact</a>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
        <div className="footer-socials">
          <a href="https://twitter.com/engelfinedesign" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://instagram.com/engelfinedesign" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://facebook.com/engelfinedesign" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Engel Fine Design. All rights reserved.</p>
        <p>Built by <a href="www.crittercodes.com">CritterCodes</a></p>
      </div>
    </footer>
  );
}

export default Footer;
