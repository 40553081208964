import React, { useState, useEffect } from 'react';
import axios from 'axios';
import algosdk from 'algosdk';
import multihash from 'multihashes';
import CID from 'cids';

const BindRelic = ({ peraWallet }) => {
  const [relics, setRelics] = useState([]);
  const [selectedRelic, setSelectedRelic] = useState(null);
  const [isMinting, setIsMinting] = useState(false);
  const [mintingMessage, setMintingMessage] = useState('');

  const algodClient = new algosdk.Algodv2('', 'https://testnet-api.algonode.cloud', '');

  useEffect(() => {
    fetchUnmintedRelics();
  }, []);

  const fetchUnmintedRelics = async () => {
    try {
      const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/unminted`);
      setRelics(response.data);
    } catch (error) {
      console.error('Error fetching unminted relics:', error);
    }
  };

  const handleMintRelic = async () => {
    if (!selectedRelic) return;

    setIsMinting(true);
    setMintingMessage('Minting NFT...');

    try {
      const txnId = await mintNFT(selectedRelic);

      setMintingMessage('Updating database...');

      // Update the database to mark the relic as minted
      await axios.patch(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/relics/${selectedRelic._id}`, {
        minted: true,
        txnId,
      });

      setMintingMessage('Relic minted successfully!');
      fetchUnmintedRelics(); // Refresh the list
    } catch (error) {
      console.error('Error minting NFT:', error);
      setMintingMessage('Error minting NFT. Please try again.');
    } finally {
      setIsMinting(false);
    }
  };

  const mintNFT = async (relic) => {
    const suggestedParams = await algodClient.getTransactionParams().do();

    const reserve = algosdk.encodeAddress(multihash.decode(new CID(relic.ipfsHash).multihash).digest);

    const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
      from: peraWallet.accounts[0], // User's wallet address
      note: new Uint8Array(Buffer.from(JSON.stringify({ standard: 'arc69', description: relic.description }))),
      suggestedParams: suggestedParams,
      assetName: relic.name,
      unitName: relic.unitName,
      assetURL: `ipfs://${relic.ipfsHash}`, // Use the IPFS hash stored in the relic data
      total: 1,
      decimals: 0,
      defaultFrozen: false,
      manager: peraWallet.accounts[0], // User's wallet address as the manager
      reserve,
    });

    // Sign the transaction using the user's wallet (e.g., Pera Wallet)
    const signedTxn = await peraWallet.signTransaction(txn.toByte());

    // Send the signed transaction to the backend for submission
    const response = await axios.post(`https://${process.env.REACT_APP_API_BASE_URL}/api/v1/algo/mint`, {
      signedTxn,
      relicId: relic._id,
    });

    return response.data.txId;
  };

  return (
    <div className="bind-relic">
      <h2>Bind Relic</h2>
      <p>Select a relic to mint into an NFT:</p>
      <select onChange={(e) => setSelectedRelic(relics.find(r => r._id === e.target.value))}>
        <option value="">Select a relic</option>
        {relics.map(relic => (
          <option key={relic._id} value={relic._id}>{relic.name}</option>
        ))}
      </select>
      <button onClick={handleMintRelic} disabled={isMinting || !selectedRelic}>
        {isMinting ? 'Minting...' : 'Mint Relic'}
      </button>
      {mintingMessage && <p>{mintingMessage}</p>}
    </div>
  );
};

export default BindRelic;
