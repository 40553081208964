import React from 'react';
import IntroSection from '../../components/about-relics/intro-section/intro-section.component';
import RelicTypes from '../../components/about-relics/relic-types/relic-types.component';
import BlockchainBinding from '../../components/about-relics/blockchain-binding/blockchain-binding.component';
import OwnershipSection from '../../components/about-relics/ownership/ownership.component';
import CallToAction from '../../components/about-relics/cta/cta.component';
import './about-relics.page.css';

const AboutRelics = () => {
  return (
    <div className="about-relics-container">
      <IntroSection />
      <RelicTypes />
      <OwnershipSection />
      <CallToAction />
    </div>
  );
};

export default AboutRelics;
