import React from 'react';
import './intro-section.component.css';

const IntroSection = () => {
  return (
    <section className="about-relics-intro card">
      <h1>About <span className="relics-word">Relics</span></h1>
      <p>
        [efd]Relics are a groundbreaking fusion of physical craftsmanship and cutting-edge blockchain technology.
        By owning a Relic, you possess both luxury jewelry and its digital counterpart—an NFT stored on the Algorand blockchain, 
        ensuring authenticity, ownership, and provenance.
      </p>
    </section>
  );
};

export default IntroSection;
