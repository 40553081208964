import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './nft-section.component.css';

const NFTSection = () => {
  // Slider settings for mobile view
  const settings = {
    dots: true, // Enable dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: "#ff007f",
          borderRadius: "50%",
          display: "inline-block",
          cursor: "pointer"
        }}
      ></div>
    )
  };

  return (
    <section className="nft-section">
      <div className="card-container desktop-only">
        {/* Relics Card */}
        <div className="nft-card">
          <h2 className="nft-title relics-word">Relics</h2>
          <p className="nft-description">
            Own luxury jewelry secured by the blockchain. True ownership, forever.
          </p>
          <button className="nft-button" onClick={() => window.location.href='/about-relics'}>
            Learn More
          </button>
        </div>

        {/* Visions Card */}
        <div className="nft-card">
          <h2 className="nft-title visions-word">Visions</h2>
          <p className="nft-description">
            Earn commissions by owning a Vision. Let others bring it to life as a Relic.
          </p>
          <button className="nft-button" onClick={() => window.location.href='/visions'}>
            Learn More
          </button>
        </div>
      </div>

      {/* Carousel for mobile */}
      <div className="mobile-only">
        <Slider {...settings}>
          {/* Relics Card */}
          <div className="nft-card">
            <h2 className="nft-title relics-word">Relics</h2>
            <p className="nft-description">
              Own luxury jewelry secured by the blockchain. True ownership, forever.
            </p>
            <button className="nft-button relics-button" onClick={() => window.location.href='/about-relics'}>
              Learn More
            </button>
          </div>

          {/* Visions Card */}
          <div className="nft-card">
            <h2 className="nft-title visions-word">Visions</h2>
            <p className="nft-description">
              Earn commissions by owning a Vision. Let others bring it to life as a Relic.
            </p>
            <button className="nft-button visions-button" onClick={() => window.location.href='/about-visions'}>
              Learn More
            </button>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default NFTSection;
