import algosdk from 'algosdk';

export async function generatePaymentTxns({ to, initiatorAddr }) {
  const algodClient = new algosdk.Algodv2('', 'https://testnet-api.algonode.cloud', '');
  const suggestedParams = await algodClient.getTransactionParams().do();

  const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
    from: initiatorAddr,
    to,
    amount: 0e6,
    note: new Uint8Array(Buffer.from('auth')),
    suggestedParams,
  });

  return [{ txn, signers: [initiatorAddr] }];
}
