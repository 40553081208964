import React, { useState, useEffect } from 'react';
import './profile-settings.page.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const NFTSelectionModal = ({ walletAddress, onSelect, onClose }) => {
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    useEffect(() => {
        if (walletAddress) {
            fetchNFTs(walletAddress);
        }
    }, [walletAddress]);

    const fetchNFTs = async (walletAddress) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/algo/nfts/${walletAddress}`);
            const data = await response.json();
            setNfts(data.nfts);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
            setLoading(false);
        }
    };

    const ipfsUrlTohttps = (url) => {
        if (!url) return '';
        return url.startsWith('ipfs://') ? url.replace('ipfs://', 'https://ipfs.algonode.xyz/ipfs/') : url;
    };

    const totalPages = Math.ceil(nfts.length / itemsPerPage);
    const paginatedNfts = nfts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    

    return (
        <div className="nft-selection-modal">
            <div className="modal-content">
                <h3>Select NFT as Profile Picture</h3>
                <button className="close-button" onClick={onClose}>X</button>
                {loading ? (
                    <p>Loading NFTs...</p>
                ) : (
                    <div className="nft-gallery">
                        {paginatedNfts.length > 0 ? (
                            paginatedNfts.map((nft, index) => (
                                <div key={index} className="nft-item" onClick={() => onSelect(ipfsUrlTohttps(nft.url))}>
                                    <img src={ipfsUrlTohttps(nft.url)} alt={nft.name} className="nft-image" />
                                    <p>{nft.name}</p>
                                </div>
                            ))
                        ) : (
                            <p>No NFTs found in this wallet.</p>
                        )}
                    </div>
                )}
                <div className="pagination">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>
        </div>
    );
};

const SocialMediaSelectionModal = ({ onSelectSocial, onClose }) => {
    const socialPlatforms = ['twitter', 'facebook', 'instagram', 'linkedin', 'github', 'codepen', 'reddit'];

    return (
        <div className="social-selection-modal">
            <div className="modal-content">
                <h3>Select Social Media Platform</h3>
                <button className="close-button" onClick={onClose}>X</button>
                <div className="social-buttons">
                    {socialPlatforms.map(platform => (
                        <button
                            key={platform}
                            className="social-button"
                            onClick={() => onSelectSocial(platform)}
                        >
                            {platform.charAt(0).toUpperCase() + platform.slice(1)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

const ProfileSettings = ({ walletAddress, username, setUsername, onDisconnect }) => {
    const [profilePic, setProfilePic] = useState('path/to/default-pic.jpg');
    const [bio, setBio] = useState('');
    const [websites, setWebsites] = useState(['']);
    const [socials, setSocials] = useState([]);
    const [isSocialModalOpen, setIsSocialModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    useEffect(() => {
      if (walletAddress) {
        fetchProfileData(walletAddress);
      }
    }, [walletAddress]);
  
    const fetchProfileData = async (walletAddress) => {
      try {
        // Make a GET request to the API to retrieve profile data
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/collectors/${walletAddress}`);
  
        if (response.ok) {
          // Parse the response as JSON
          const data = await response.json();
  
          // Update state variables with the fetched profile data
          setUsername(data.username || username || '' ); // Use an empty string instead of null/undefined
          setBio(data.bio || ''); // Use an empty string
          setWebsites(data.websites || ['']); // Ensure an array with at least one item
          setSocials(data.socials || []); // Ensure an array
          setProfilePic(data.picture || 'path/to/default-pic.jpg');
        } else {
          console.error('Failed to fetch profile data.');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
  
    const handleSocialChange = (index, value) => {
      const updatedSocials = [...socials];
      updatedSocials[index].handle = value;
      setSocials(updatedSocials);
    };
  
    const handleAddSocial = (platform) => {
      if (platform && !socials.some(social => social.platform === platform)) {
        setSocials([...socials, { platform, handle: '' }]);
        setIsSocialModalOpen(false);
      }
    };
  
    const handleRemoveSocial = (index) => {
      const updatedSocials = socials.filter((_, i) => i !== index);
      setSocials(updatedSocials);
    };
  
    const handleWebsiteChange = (index, value) => {
      const updatedWebsites = [...websites];
      updatedWebsites[index] = value || ''; // Ensure value is always a string
      setWebsites(updatedWebsites);
    };
  
    const handleAddWebsite = () => {
      setWebsites([...websites, '']); // Add an empty string for a new website
    };
  
    const handleRemoveWebsite = (index) => {
      const updatedWebsites = websites.filter((_, i) => i !== index);
      setWebsites(updatedWebsites);
    };
  
    const handleSaveChanges = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/collectors/${walletAddress}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            bio,
            websites,
            socials,
            picture: profilePic,
          }),
        });
  
        if (response.ok) {
          alert('Profile updated successfully!');
        } else {
          alert('Failed to update profile.');
        }
      } catch (error) {
        console.error('Error saving profile:', error);
        alert('Error saving profile.');
      }
    };
  
    return (
        <div className="profile-settings">
          <div className="profile-section">
            <div className="profile-pic-container" onClick={() => setIsModalOpen(true)}>
              <img src={profilePic} alt="Profile" className="profile-pic-preview" />
            </div>
          </div>
      
          {isModalOpen && (
            <NFTSelectionModal
              walletAddress={walletAddress}
              onSelect={setProfilePic}
              onClose={() => setIsModalOpen(false)}
            />
          )}
      
          <div className="profile-section">
            <label>Username</label>
            <input
              type="text"
              value={username || ''} // Ensure it's never undefined or null
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </div>
      
          <div className="profile-section">
            <label>Bio</label>
            <textarea
              value={bio || ''} // Ensure it's never undefined or null
              onChange={(e) => setBio(e.target.value)}
              placeholder="Tell us about yourself"
            />
          </div>
      
          <div className="profile-section">
            <label>Websites</label>
            {websites.map((website, index) => (
              <div key={index} className="website-input">
                <input
                  type="text"
                  value={website || ''} // Ensure it's never undefined or null
                  onChange={(e) => handleWebsiteChange(index, e.target.value)}
                  placeholder="Enter website URL"
                />
                <button onClick={() => handleRemoveWebsite(index)} className="remove-button">×</button>
              </div>
            ))}
            <div className="add-website-button">
              <button onClick={handleAddWebsite} className="add-button"><FontAwesomeIcon icon={faPlus} /></button>
            </div>
          </div>
      
          <div className="profile-section">
            <label>Social Media Links</label>
            {socials.map((social, index) => (
              <div key={index} className="social-input">
                <label>{social.platform.charAt(0).toUpperCase() + social.platform.slice(1)}:</label>
                <input
                  type="text"
                  value={social.handle || ''} // Ensure it's never undefined or null
                  onChange={(e) => handleSocialChange(index, e.target.value)}
                  placeholder={`Enter your ${social.platform} handle`}
                />
                <button onClick={() => handleRemoveSocial(index)} className="remove-button">×</button>
              </div>
            ))}
            <div className="add-social-button">
              <button onClick={() => setIsSocialModalOpen(true)} className="add-button"><FontAwesomeIcon icon={faPlus} /></button>
            </div>
          </div>
      
          {isSocialModalOpen && (
            <SocialMediaSelectionModal
              onSelectSocial={handleAddSocial}
              onClose={() => setIsSocialModalOpen(false)}
            />
          )}
      
          <div className="profile-section">
            <label>Wallet Address</label>
            <div className="wallet-address">
              {walletAddress ? (
                <>
                  <span>{walletAddress.slice(0, 3)}...{walletAddress.slice(-4)}</span>
                  <button onClick={onDisconnect} className="disconnect-button">Disconnect</button>
                </>
              ) : (
                <span>No wallet connected</span>
              )}
            </div>
          </div>
      
          <div className="profile-section">
            <button className="save-button" onClick={handleSaveChanges}>Save</button>
          </div>
        </div>
      );
  };
  
  export default ProfileSettings;