import React from 'react';
import './cta.component.css';

const CallToAction = () => {
  return (
    <section className="about-relics-cta">
      <h2>Ready to Explore More?</h2>
      <button className="explore-button">Explore Relics Collection</button>
      <button className="marketplace-button">Visit the Marketplace</button>
    </section>
  );
};

export default CallToAction;
