import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import RelicManagement from '../relic-management/relic-management.page.js';
import AddRelic from '../add-relic/add-relic.page.js';
import Relic from '../relic/relic.page.js';
import './admin-dash.page.css';

const AdminDash = ({ authToken }) => { // Removed walletAddress, since it's not needed for JWT-based auth
  return (
    <div className="admin-dash">
      <header className="admin-dash-header">
        <h1>Admin Dashboard</h1>
      </header>
      <nav className="admin-dash-nav">
        <ul>
          <li><Link to="/admin-dashboard/relics">Manage Relics</Link></li>
          <li><Link to="/admin-dashboard/users">Manage Users</Link></li>
          <li><Link to="/admin-dashboard/settings">Settings</Link></li>
        </ul>
      </nav>
      <main className="admin-dash-content">
        <Routes>
          <Route path="/" element={<p>Select an option from the menu to get started.</p>} />
          <Route path="relics" element={<RelicManagement authToken={authToken} />} /> {/* Pass only authToken */}
          <Route path="add-relic" element={<AddRelic authToken={authToken} />} /> {/* Pass only authToken */}
          <Route path="/relic/:relicID" element={<Relic authToken={authToken} />} />

          {/* Add more child routes as needed */}
        </Routes>
      </main>
    </div>
  );
};

export default AdminDash;
