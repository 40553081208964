import React from 'react';
import './relic-types.component.css';

const RelicTypes = () => {
  return (
    <section className="about-relics-types">
      <h2>Types of <span className="relics-word">Relics</span></h2>
      <div className="types-content">
        <div className="type-card">
          <h3>One-of-a-Kind Relics (1/1)</h3>
          <p>
            Each 1/1 Relic is a unique creation, offering ultimate exclusivity for collectors who seek irreplaceable luxury.
          </p>
        </div>
        <div className="type-card">
          <h3>Limited Production Relics (LTD)</h3>
          <p>
            Crafted in exclusive batches, limited production Relics combine craftsmanship and rarity for discerning collectors.
          </p>
        </div>
      </div>
    </section>
  );
};

export default RelicTypes;
