import React from 'react';
import './home.page.css';

import Hero2 from '../../components/home/hero2/hero2.component.js';
import NFTSection from '../../components/home/nft-section/nft-section.component.js';


const HomePage = () => {
    return (
        <div className="homepage">
            {/* Hero Section */}
            <Hero2 />
            {/* Relics Info Section */}
            <NFTSection />  {/* Add the new Relics Info component here */}
        </div>
    );
};

export default HomePage;
