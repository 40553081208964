import React from 'react';
import './ownership.component.css';

const OwnershipSection = () => {
  return (
    <section className="about-relics-ownership card">
      <h2>Ownership & Provenance</h2>
      <p>
        As a collector, you own both the physical Relic and its digital NFT. The NFT serves as a permanent record of your Relic’s journey, 
        ensuring its value and authenticity throughout its lifetime.
      </p>
      <p>
        You can trade, resell, or transfer your Relic with full confidence that its authenticity is secured on the blockchain, 
        preserving its heritage and value.
      </p>
    </section>
  );
};

export default OwnershipSection;
