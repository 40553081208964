import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.layout.css'; // The updated CSS you provided
import { FaHome, FaUserShield } from 'react-icons/fa';

const Sidebar = ({ isAdmin, walletAddress, nfd, profilePic, onConnect }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  

  return (
    <>
      <div
        className={`sidebar ${isMobileMenuOpen ? 'open' : ''} ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="logo">
          <Link to="/" onClick={toggleMobileMenu}>
            [efd]<br /><span className="logo-subtext">Relics</span>
          </Link>
        </div>
        <nav className="nav">
          <Link to="/" onClick={toggleMobileMenu}>
            <FaHome className="nav-icon" />
            <span className="nav-text">Home</span>
          </Link>
          {isAdmin && (
            <Link to="/admin-dashboard" onClick={toggleMobileMenu}>
              <FaUserShield className="nav-icon" />
              <span className="nav-text">Dash</span>
            </Link>
          )}
        </nav>
        <div className="theme-toggle-container">
          <label className="switch">
            <input
              type="checkbox"
              checked={theme === 'dark'}
              onChange={toggleTheme}
            />
            <span className="slider round"></span>
          </label>
          <span className="theme-label">Dark Mode</span>
        </div>
        <div className="sidebar-footer">
          {walletAddress ? (
            <Link to="/profile-settings" className="connected profile-link">
              <img src={profilePic} alt="Profile" className="profile-pic" />
              <div className="wallet-info">
                {nfd ? <span>{nfd}</span> : <span>{walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}</span>}
                
              </div>
            </Link>
          ) : (
            <button onClick={onConnect} className="pill-button">
              Connect Wallet
            </button>
          )}
        </div>
        {isMobileMenuOpen && <div className="overlay show" onClick={toggleMobileMenu}></div>}
      </div>
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </>
  );
};

export default Sidebar;
