// react packages
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// api and utils imports
import { authenticateWallet, checkAndCreateCollector } from './api/auth.api.js';
import { generatePaymentTxns } from './utils/blockchain.utils.js';
import { getStoredWalletInfo, saveWalletInfo, clearWalletInfo } from './utils/storage.utils.js';
// other packages
import { PeraWalletConnect } from '@perawallet/connect';
import {jwtDecode} from 'jwt-decode'; // Correct import here
// layouts
import Sidebar from './layouts/sidebar/sidebar.layout.js';
import Footer from './layouts/footer/footer.layout.js';
// public pages
import HomePage from './pages/home/home.page.js';
import AboutRelics from './pages/about-relics/about-relics.page.js';
import ProfilePage from './pages/profile/profile.page.js';

// user pages
import ProfileSettings from './pages/profile-settings/profile-settings.page.js';

// admin pages
import AdminDash from './pages/admin-dash/admin-dash.page.js';
// styles
import './App.css';

const peraWallet = new PeraWalletConnect({ chainId: 416002 }); // TestNet

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [username, setUsername] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [nfd, setNFD] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (walletAddress) {
        await getProfileInfo(walletAddress);
      }
    };
  
    fetchData();
  }, [walletAddress]); // This useEffect will only trigger when walletAddress changes
  

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    const storedWallet = localStorage.getItem('walletAddress');

    // Check if the token is valid before trying to decode it
    if (storedToken && storedToken.split('.').length === 3) {
      try {
        const decodedToken = jwtDecode(storedToken);
        setIsAdmin(decodedToken.isAdmin);
        setAuthToken(storedToken);
        setWalletAddress(storedWallet);
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    } else {
      console.warn('Invalid token or token missing');
      localStorage.removeItem('authToken'); // Clean up invalid token
    }

    // Reconnect session logic
    peraWallet.reconnectSession()
      .then((accounts) => {
        if (accounts.length) {
          const walletAddress = accounts[0];
          setWalletAddress(walletAddress);
          localStorage.setItem('walletAddress', walletAddress);
          checkAndCreateCollector(walletAddress); // Check and create collector if necessary
          getProfileInfo(walletAddress); // Fetch profile information after reconnection
        }
      })
      .catch(error => console.log('Reconnect failed:', error));
  }, []);

  const getProfileInfo = async (walletAddress) => {
    try {
      console.log('Fetching profile and NFD data for:', walletAddress);
      // Fetch both the profile and NFD data in parallel
      const [profileResponse, nfdResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/collectors/${walletAddress}`),
        fetch(`https://api.nf.domains/nfd/lookup?address=${walletAddress}`)
      ]);
  
      let fetchedUsername = null;
  
      if (profileResponse.ok) {
        const profileData = await profileResponse.json();
        setProfilePic(profileData.picture || 'path/to/default-pic.jpg');
  
        // Check if the profile has a username
        if (profileData.username) {
          fetchedUsername = profileData.username; // Set to username from profile
        }
      } else {
        console.error('Failed to fetch profile data.');
      }
  
      if (nfdResponse.ok) {
        const nfdData = await nfdResponse.json();
        const nfdName = nfdData[walletAddress]?.name || null;
        setNFD(nfdName);
  
        // If username is not set from profile, set it to NFD name
        if (!fetchedUsername && nfdName) {
          fetchedUsername = nfdName;
        }
      } else {
        console.error('Failed to fetch NFD data.');
      }
  
      // Finally, set the username (either from profile or NFD)
      if (fetchedUsername) {
        setUsername(fetchedUsername);
      }
    } catch (error) {
      console.error('Error fetching profile or NFD info:', error);
    }
  };
  

  async function handleConnectWallet() {
    try {
      const newAccounts = await peraWallet.connect();
      const walletAddress = newAccounts[0];
      const recipientAddress = 'XP5QBD6NQRPJNRHKFFG6MWWSLL7EPOIUU56OQZSGJX5G6ADWWRWDHR35NI';

      const txn = await generatePaymentTxns({
        to: recipientAddress,
        initiatorAddr: walletAddress,
      });

      console.log("Transaction Created:", txn);

      // Sign the transaction using Pera Wallet
      const signedTxnGroup = await peraWallet.signTransaction([txn]);

      if (signedTxnGroup[0]) {
        const token = await authenticateWallet(walletAddress, signedTxnGroup[0]);

        // Store the token and wallet in local storage
        saveWalletInfo(walletAddress, token);

        setAuthToken(token);
        setWalletAddress(walletAddress);

        checkAndCreateCollector(walletAddress); // Check and create collector if necessary
        getProfileInfo(walletAddress);
      } else {
        console.error('Transaction signing failed. No signed transaction blob found.');
      }
    } catch (error) {
      console.error('Error during wallet connection or transaction:', error);
    }
  }

  const handleDisconnectWallet = () => {
    peraWallet.disconnect();
    setWalletAddress(null);
    setAuthToken(null);
    clearWalletInfo();
  };

  return (
    <Router>

      <Sidebar
        isAdmin={isAdmin}
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
        nfd={nfd}
        profilePic={profilePic}
        onConnect={handleConnectWallet}
      />
      <div className="page-content">
        <Routes>
          <Route path="/" element={<HomePage isAdmin={isAdmin} walletAddress={walletAddress} />} />
          <Route path="/about-relics" element={<AboutRelics />} />
          <Route path="/admin-dashboard/*" element={<AdminDash isAdmin={isAdmin} authToken={authToken} />} />
          <Route path="/profile-settings" element={<ProfileSettings authToken={authToken} walletAddress={walletAddress} username={username} setUsername={setUsername} onDisconnect={handleDisconnectWallet} />} />
          <Route path="/profiles/:username" element={<ProfilePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
