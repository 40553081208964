import axios from 'axios';

const baseApiUrl = process.env.REACT_APP_API_BASE_URL;

export const authenticateWallet = async (walletAddress, signedTxn) => {
  try {
    const authResponse = await axios.post(`${baseApiUrl}/api/v1/auth`, {
      walletAddress,
      signedTxn,
    });
    return authResponse.data.token;
  } catch (error) {
    throw new Error('Authentication failed');
  }
};

export const checkAndCreateCollector = async (walletAddress) => {
  try {
    const response = await axios.get(`${baseApiUrl}/api/v1/collectors/${walletAddress}`);
    console.log('Collector already exists.');
  } catch (error) {
    if (error.response && error.response.status === 404) {
      try {
        const newCollector = {
          address: walletAddress,
          username: '',
          picture: 'images/default-profile-pic.png',
          bio: '',
          websites: [],
          socials: [],
        };
        const createResponse = await axios.post(`${baseApiUrl}/api/v1/collectors`, newCollector);
        if (createResponse.status === 201) {
          console.log('New collector created successfully.');
        } else {
          console.error('Failed to create new collector.');
        }
      } catch (creationError) {
        console.error('Error during collector creation:', creationError);
      }
    } else {
      console.error('Error checking collector:', error);
    }
  }
};
