export const getStoredWalletInfo = () => ({
  storedWallet: localStorage.getItem('walletAddress'),
  storedToken: localStorage.getItem('authToken'),
});

export const saveWalletInfo = (walletAddress, authToken) => {
  localStorage.setItem('walletAddress', walletAddress);
  localStorage.setItem('authToken', authToken);
};

export const clearWalletInfo = () => {
  localStorage.removeItem('walletAddress');
  localStorage.removeItem('authToken');
};
